import {Icon} from '@gravity-ui/uikit';
import {
    unstable_TreeList as TreeListApp,
    unstable_useList as useList,
} from '@gravity-ui/uikit/unstable';
import {useNavigate} from 'react-router-dom';

const TreeList = (props) => {
    const items = props.itemsMenu;
    const list = useList({items});
    const navigate = useNavigate();

    const handleItemClick = ({id}) => {
        list.state.setSelected({[id]: true});
        navigate(items[id].code);
    };

    return (
        <TreeListApp
            list={list}
            onItemClick={handleItemClick}
            size="l"
            mapItemDataToContentProps={({title, icon}) => ({startSlot: icon, title})}
        />
    );
};

export default TreeList;
