import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {loadState} from '.';

export const LAYOUTS_PERSISTENT_STATE = 'layouts';

export interface EntitieState {
    id: string | null;
    name: string | null;
    code: string | null;
    entity: string | null;
    list: string | null;
    show: string | null;
    edit: string | null;
    create: string | null;
    icon: string | null;
    parent: string | null;
}

export interface LayoutState {
    code: string | null;
    entities: EntitieState[];
    id: string | null;
    name: string | null;
    root: string | null;
}

export interface LayoutsState {
    layouts: LayoutState[] | [];
}

const initialState: LayoutsState = {
    layouts: loadState(LAYOUTS_PERSISTENT_STATE) ?? [],
};

export const layoutsSlice = createSlice({
    name: 'layouts',
    initialState,
    reducers: {
        loadData: (state, action: PayloadAction<[]>) => {
            console.log('layoutsSlice --- loadData');
            state.layouts = action.payload;
        },
    },
});

export default layoutsSlice.reducer;
export const layoutsActions = layoutsSlice.actions;
