import {
    ApolloClient,
    ApolloProvider,
    HttpLink,
    InMemoryCache,
    gql,
    useApolloClient,
} from '@apollo/client';
import {gqlClient} from 'api';

const GQLQuery = {
    listingForEntity: (
        entityId,
        resourceName,
        sort,
        pagination = {page: 1, perPage: 50},
        filter = {},
    ) =>
        new Promise((resolve, reject) => {
            let query = gql(
                'query { listingForEntity(' +
                    'adminEntity: ' +
                    parseInt(entityId) +
                    ',' +
                    'resourceName: "' +
                    resourceName +
                    '",' +
                    'sort: "' +
                    JSON.stringify(sort).replaceAll('"', '\\"') +
                    '",' +
                    'pagination: "' +
                    JSON.stringify(pagination).replaceAll('"', '\\"') +
                    '",' +
                    'filter: "' +
                    JSON.stringify(filter).replaceAll('"', '\\"') +
                    '",' +
                    '){total, data}' +
                    '}',
            );
            gqlClient
                .query({query: query})
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        }),
};

export default GQLQuery;
