import Login from 'views/Login';
import Main from 'views/Main';
import Entity from 'views/Entity';
import AdminLayout from 'views/AdminLayout';
import PrivateRoute from './PrivateRoute';

const routes = [
    {
        path: '/',
        element: <PrivateRoute />,
        children: [
            {
                path: '/',
                element: <Main />,
                children: [
                    {
                        path: '/:layoutCode',
                        element: <AdminLayout />,
                        children: [
                            {
                                path: '/:layoutCode/:entityCode',
                                element: <Entity type="list" />,
                            },
                            {
                                path: '/:layoutCode/:entityCode/create',
                                element: <Entity type="create" />,
                            },
                            {
                                path: '/:layoutCode/:entityCode/:id',
                                element: <Entity type="edit" />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: '/login',
        element: <Login />,
    },
];

export default routes;
