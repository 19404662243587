import {useLocation, Navigate} from 'react-router-dom';
import {useRoutes} from 'react-router-dom';
import routes from './routers/index';
import Error404 from 'components/errors/Error404';

export default function App() {
    const element = useRoutes(routes);
    const location = useLocation();

    // TODO: Подумать какую страничку считать главной, а пока редиректим на мейн
    if (location.pathname == '/') return <Navigate to="/main" />;

    return <div>{element ?? <Error404 />}</div>;
}
