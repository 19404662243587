import {Button, TextInput} from '@gravity-ui/uikit';
import {useState} from 'react';
import {Navigate} from 'react-router-dom';
import {useAuth} from 'hooks/AuthProvider';
import './styles.css';

export default function Login() {
    const auth = useAuth();

    if (auth.token) return <Navigate to="/" />;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onButtonClick = () => {
        auth.loginAction(email, password);
    };

    return (
        <div className={'mainContainer'}>
            <div className={'titleContainer'}>
                <div>Авторизация</div>
            </div>
            <br />
            <div className={'inputContainer'}>
                <TextInput
                    id="email"
                    type="text"
                    size="xl"
                    placeholder="Логин"
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <br />
            <div className={'inputContainer'}>
                <TextInput
                    id="password"
                    type="password"
                    size="xl"
                    placeholder="Пароль"
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <br />
            <div className={'inputContainer'}>
                <Button view="outlined-action" onClick={onButtonClick} size="xl">
                    Войти
                </Button>
            </div>
        </div>
    );
}
