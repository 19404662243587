import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider, configure, ToasterComponent, ToasterProvider} from '@gravity-ui/uikit';
import {Provider} from 'react-redux';
import {storage} from './storages/store';
import AuthProvider from './hooks/AuthProvider';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@gravity-ui/uikit/styles/fonts.css';
import '@gravity-ui/uikit/styles/styles.css';
import './styles/globals.scss';

configure({
    lang: 'ru',
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Provider store={storage}>
        <BrowserRouter basename="admin/">
            <ToasterProvider>
                <ThemeProvider theme={localStorage.getItem('theme') || 'dark'}>
                    <AuthProvider>
                        <App />
                        <ToasterComponent />
                    </AuthProvider>
                </ThemeProvider>
            </ToasterProvider>
        </BrowserRouter>
    </Provider>,
);
