import {Outlet, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import {RootState} from 'storages/store';
import {useAuth} from 'hooks/AuthProvider';
import Error404 from 'components/errors/Error404';
import Icons from 'components/ui/Icons';
import TreeList from 'components/ui/TreeList/index';
import {DatePicker} from '@gravity-ui/date-components';
import {useNavigate} from 'react-router-dom';
import {
    Icon,
    Menu,
    Button,
    Container,
    Row,
    Col,
    Flex,
    User,
    Toc,
    Text,
    TextInput,
    Select,
    Tabs,
} from '@gravity-ui/uikit';
import {
    CircleExclamationFill,
    Gear,
    Plus,
    ArrowShapeDownToLine,
    LayoutSplitColumns3,
    BarsDescendingAlignCenter,
} from '@gravity-ui/icons';
import './styles.css';

function AdminLayout() {
    const auth = useAuth();
    const navigate = useNavigate();
    const params = useParams();
    const storage = localStorage.getItem('user') || null;
    const [user, setUser] = useState(storage && JSON.parse(storage).user);
    const adminLayout = useSelector((s) => s.adminConfigure.layouts);
    const layout = adminLayout.find((layout) => layout.code === params.layoutCode);

    const [active, setActive] = useState('control');
    const [activeTab, setActiveTab] = useState('ОСНОВНОЕ');

    if (!layout) {
        return <Error404 />;
    }

    let itemsMenu = [];
    layout?.entities.map((entity) => {
        itemsMenu.push({
            title: entity.name,
            key: entity.id,
            code: entity.code,
            icon: <Icons name={entity.icon} />,
        });
    });

    const openEntity = (code) => {
        navigate(code);
    };

    return (
        <Row>
            <Col s={12}>
                <Row className="adminLayoutRow">
                    <Col s="10">
                        <Text width={'max'} style={{float: 'left'}}>
                            AdminLayout
                        </Text>
                        <Text width={'max'} style={{float: 'left'}}>
                            {params.layoutCode}
                        </Text>
                    </Col>
                    <Col s="2" style={{display: 'flex', justifyContent: 'right'}}>
                        <User
                            // avatar={{
                            //     imgUrl: 'https://loremflickr.com/640/480/cats?lock=8610182282084352',
                            // }}
                            // name={user.username}
                            description={user.username}
                            style={{paddingRight: '20px'}}
                        />
                        <Button
                            onClick={() => auth.logOut()}
                            view="outlined-action"
                            size="l"
                            style={{fontSize: '0.8rem'}}
                        >
                            Выход
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col s={2} style={{minHeight: '100vh', background: '#222425'}}>
                <TreeList itemsMenu={itemsMenu} />
            </Col>
            <Col s={10} style={{padding: '20px'}}>
                <Outlet />
            </Col>
        </Row>
    );
}

export default AdminLayout;
