import {Menu, Icon} from '@gravity-ui/uikit';
import {Bars} from '@gravity-ui/icons';

const SubMenu = (props) => {
    const items = props.subMenu;

    return (
        <Menu size="l">
            {items.map((field, index) => {
                return (
                    <Menu.Group
                        iconStart={<Icon size={16} data={Bars} />}
                        label={field.label}
                        key={index}
                    >
                        {field.items.map((item, index) => {
                            // TODO: САНЕК ГЛЯНЬ СЮДА: Вот здесь запараб "keys" может быть не один, "values" сразу по нескольким или одному
                            // onClick добавить надо по этим параметрам.
                            // console.log(Object.keys(item.value));
                            // console.log(Object.values(item.value));
                            // console.log(Object.entries(item.value));
                            return <Menu.Item key={index}>{item.label}</Menu.Item>;
                        })}
                    </Menu.Group>
                );
            })}
        </Menu>
    );
};

export default SubMenu;
