import {DatePicker} from '@gravity-ui/date-components';
import {useEffect, useRef, useState, useContext} from 'react';
import {useNavigate, useOutletContext, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Error404 from 'components/errors/Error404';
import SubMenu from 'components/ui/SubMenu';
import {
    Button,
    Container,
    Label,
    Text,
    TextInput,
    useToaster,
    Table,
    withTableActions,
    withTableSelection,
    withTableSettings,
    withTableSorting,
    Flex,
    Row,
    Col,
    Select,
    Toc,
    Icon,
} from '@gravity-ui/uikit';
import {
    CircleExclamationFill,
    Gear,
    Plus,
    ArrowShapeDownToLine,
    LayoutSplitColumns3,
    BarsDescendingAlignCenter,
} from '@gravity-ui/icons';
import GQLQuery from 'api/GQLQuery';

const DefaultList = (props) => {
    const toaster = useToaster();
    const navigate = useNavigate();
    const params = useParams();
    const [listing, setListing] = useState(null);
    const [total, setTotal] = useState(0);

    if (!props.entity) {
        return <Error404 title="Страница не найдена" />;
    }

    const list = JSON.parse(props.entity.list);

    useEffect(() => {
        GQLQuery.listingForEntity(
            props.entity.id,
            params.layoutCode + '/' + params.entityCode,
            list.sort,
            {page: 1, perPage: 50},
            {},
        )
            .then((result) => {
                setListing(result.data.listingForEntity.data);
                setTotal(result.data.listingForEntity.total);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [setListing]);

    const EntityTableAction = withTableActions(Table);
    const EntityTableSelection = withTableSelection(EntityTableAction);
    const EntityTableSettings = withTableSettings({width: 200, sortable: false})(
        EntityTableSelection,
    );
    const EntityTable = withTableSorting(EntityTableSettings);

    const columns = [];
    const data = [];
    const subMenu = [];

    list.filterSidebar?.filterLists.map((field) => {
        subMenu.push({
            icon: field.icon,
            items: field.items,
            label: field.label,
        });
    });

    list.fields.map((field) => {
        columns.push({
            id: field.name || field.source,
            name: field.label || field.props.label,
        });
    });

    listing &&
        listing.map((field) => {
            data.push({...field});
        });

    const getRowActions = () => {
        return [
            {
                text: 'Print',
                handler: () => {},
            },
            {
                text: 'Remove',
                handler: () => {},
                theme: 'danger',
            },
        ];
    };

    const initialSettings = [
        {id: 'id', isSelected: false},
        {id: 'text', isSelected: true},
    ];

    const [selectedIds, setSelectedIds] = useState([1]);
    const [settings, setSettings] = useState(initialSettings);

    return (
        <Row>
            <Col s={12} style={{paddingBottom: '1%'}}>
                <Flex space={2}>
                    <TextInput size="l" placeholder="Начните вводить..." width={200} />
                    <Select multiple={true} size="l" placeholder="Объект" width={200}>
                        <Select.Option value="val_1">Value 1</Select.Option>
                        <Select.Option value="val_2">Value 2</Select.Option>
                        <Select.Option value="val_3">Value 3</Select.Option>
                        <Select.Option value="val_4">Value 4</Select.Option>
                    </Select>
                    <Select multiple={true} size="l" placeholder="Заказчик" width={200}>
                        <Select.Option value="val_1">Value 1</Select.Option>
                        <Select.Option value="val_2">Value 2</Select.Option>
                        <Select.Option value="val_3">Value 3</Select.Option>
                        <Select.Option value="val_4">Value 4</Select.Option>
                    </Select>
                    <Select multiple={true} size="l" placeholder="Участок" width={200}>
                        <Select.Option value="val_1">Value 1</Select.Option>
                        <Select.Option value="val_2">Value 2</Select.Option>
                        <Select.Option value="val_3">Value 3</Select.Option>
                        <Select.Option value="val_4">Value 4</Select.Option>
                    </Select>
                    <DatePicker
                        hasClear
                        placeholder="Создано от"
                        format="DD/MM/YYYY"
                        size="l"
                        width={200}
                    />
                    <DatePicker
                        hasClear
                        placeholder="Создано до"
                        format="DD/MM/YYYY"
                        size="l"
                        width={200}
                    />
                    <DatePicker
                        hasClear
                        placeholder="Завершено от"
                        format="DD/MM/YYYY"
                        size="l"
                        width={200}
                    />
                    <DatePicker
                        hasClear
                        placeholder="Завершено до"
                        format="DD/MM/YYYY"
                        size="l"
                        width={200}
                    />
                </Flex>
                <Row style={{justifyContent: 'right'}}>
                    <Button view="flat-action" size="l">
                        <Icon data={Plus} size={18} />
                        Создать протокол
                    </Button>
                    <Button view="flat-action" size="l">
                        <Icon data={ArrowShapeDownToLine} size={18} />
                        Экспорт
                    </Button>
                    <Button view="flat-action" size="l">
                        <Icon data={BarsDescendingAlignCenter} size={18} />
                        Добавить фильтр
                    </Button>
                </Row>
            </Col>
            <Col s={12}>
                <Row>
                    <Col s={2}>
                        <SubMenu subMenu={subMenu} />
                    </Col>
                    <Col s={10}>
                        <EntityTable
                            onRowClick={(item) => {
                                navigate('./' + item.id);
                            }}
                            data={data}
                            columns={columns}
                            getRowActions={getRowActions}
                            selectedIds={selectedIds}
                            onSelectionChange={setSelectedIds}
                            settings={settings}
                            updateSettings={(settings) => {
                                setSettings(settings);
                                return Promise.resolve();
                            }}
                            renderControls={({DefaultApplyButton, onApply}) => (
                                <Flex gapRow="1" direction="column">
                                    <Button
                                        view="outlined-warning"
                                        onClick={() => {
                                            onApply();
                                            setSettings(initialSettings);
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <DefaultApplyButton />
                                </Flex>
                            )}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default DefaultList;
