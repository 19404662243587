// import {Link} from '@gravity-ui/uikit';
// import './styles.css';

const SimpleFormIterator = (props) => {
    return (
        <>
            {props.fields.map((field, index) => {
                // <BuildFields />
                return (
                    <div key={index}>
                        {field.type} / {field.source}
                    </div>
                );
            })}
        </>
    );
};

export default SimpleFormIterator;
