import {ApolloClient, HttpLink, InMemoryCache} from '@apollo/client';

export const gqlClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
        uri: '/gql/',
        headers: {
            authorization: localStorage.getItem('user') || '',
            'client-version': '0.0.1',
        },
    }),
    connectToDevTools: true,
});
