const filterByShowCondition = (arr, sourcePrefix, form) => {
    return arr.filter((v) => {
        if (v.show_conditions === undefined) {
            const show_condition = v.show_condition;
            if (show_condition === undefined) {
                return true;
            } else {
                const cond_prop =
                    show_condition.propertyWithPrefix === true
                        ? sourcePrefix + '.' + show_condition.property
                        : show_condition.property;
                try {
                    const cond_val = form.getValues(cond_prop);
                    return (
                        (show_condition.not && !show_condition.value.includes(cond_val)) ||
                        (!show_condition.not && show_condition.value.includes(cond_val))
                    );
                } catch (error) {
                    console.log('-=-=-=-=-=-=- v2.2.1 ERROR', error);
                    return false;
                }
            }
        } else {
            let all_cond = true;
            v.show_conditions.forEach((show_condition) => {
                if (all_cond) {
                    const cond_prop =
                        show_condition.propertyWithPrefix === true
                            ? sourcePrefix + '.' + show_condition.property
                            : show_condition.property;
                    try {
                        const cond_val = form.getValues(cond_prop);
                        all_cond =
                            (show_condition.not && !show_condition.value.includes(cond_val)) ||
                            (!show_condition.not && show_condition.value.includes(cond_val));
                    } catch (error) {
                        console.log('-=-=-=-=-=-=- v2.2.2 ERROR', error);
                        all_cond = false;
                    }
                }
            });
            return all_cond;
        }
    });
};

export default filterByShowCondition;
