import {useEffect} from 'react';
import {useLazyQuery, gql} from '@apollo/client';
import {Outlet} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispath} from 'storages/store';
import {layoutsActions} from 'storages/layouts.slice';
import {useAuth} from 'hooks/AuthProvider';
import './styles.css';

function Main() {
    const auth = useAuth();
    const dispatch = useDispatch();
    const adminLayout = useSelector((s) => s.adminConfigure.layouts);

    const [adminConfigureQuery] = useLazyQuery(
        gql(
            'query{ adminLayouts{ id, name, code, root, entities{ id, name, code, entity, list, show, edit, create, icon, parent{ id, name }, contentType{ model } } } }',
        ),
    );

    useEffect(() => {
        adminConfigureQuery()
            .then((result) => {
                dispatch(layoutsActions.loadData(result.data.adminLayouts));
            })
            .catch((err) => {
                auth.logOut();
                console.log(err);
            });
    }, []);

    return <Outlet />;
}

export default Main;
