import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, gql } from '@apollo/client';
import { useContext, createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToaster } from '@gravity-ui/uikit';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    console.log('AuthProvider');
    const storage = localStorage.getItem('user') || null;
    const [user, setUser] = useState(storage && JSON.parse(storage).user);
    const [token, setToken] = useState(storage && JSON.parse(storage).token);
    const navigate = useNavigate();
    const toaster = useToaster();

    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: new HttpLink({
            uri: '/gql/',
            headers: {
                authorization: localStorage.getItem('user') || '',
                'client-version': '0.0.1',
            },
        }),
        connectToDevTools: true,
    });

    const loginAction = (email, password) => {
        const query = gql(
            'query {' +
            'authManager(login:"' + email + '", password: "' + password + '") {' +
            // 'authManager(login:"it@kit-app.ru", password: "dohk9tofe3aeCh3Pio5eefae") {' +
            'user {id, email, username}' +
            'token' +
            '} }',
        );
        return client
            .query({
                query: query,
            })
            .then((result) => {
                if (result.data.authManager) {
                    setUser(result.data.authManager.user);
                    setToken(result.data.authManager.token);
                    localStorage.setItem('user', JSON.stringify(result.data.authManager));
                    navigate('/');
                    return;
                } else {
                    toaster.add({
                        title: 'Неверный логин или пароль',
                        theme: 'danger',
                    });
                }
            })
            .catch(() => {
                toaster.add({
                    title: 'Сервер недоступен',
                    theme: 'danger',
                });
            });
    };

    const logOut = () => {
        setUser(null);
        setToken(null);
        localStorage.removeItem('user');
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
            <ApolloProvider client={client}>{children}</ApolloProvider>
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};
