import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import AdminEntityList from 'components/AdminEntitys/AdminEntityList';
import AdminEntityEdit from 'components/AdminEntitys/AdminEntityEdit';
import AdminEntityCreate from 'components/AdminEntitys/AdminEntityCreate';
import Error404 from 'components/errors/Error404';
import './styles.css';

function Entity(props) {
    const params = useParams();

    // TODO:Эту цепочку хотелось бы в функцию для работы с лайаутами
    const adminLayout = useSelector((s) => s.adminConfigure.layouts);
    const layout = adminLayout.find((layout) => layout.code === params.layoutCode);
    const entity = layout.entities.find((entity) => entity.code === params.entityCode);
    // TODO:Эту цепочку хотелось бы в функцию для работы с лайаутами

    if (props.type == 'list') {
        return <AdminEntityList entity={entity} />;
    } else if (props.type == 'create') {
        return <AdminEntityCreate />;
    } else if (props.type == 'edit') {
        return <AdminEntityEdit />;
    } else {
        <Error404 />;
    }
}

export default Entity;
