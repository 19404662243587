// import React, {useState} from 'react';
// import {
//     List,
//     TextField,
//     ReferenceField,
//     FunctionField,
//     NumberInput,
//     CheckboxGroupInput,
//     NullableBooleanInput,
//     PasswordInput,
//     RadioButtonGroupInput,
//     UrlField,
//     SimpleForm,
//     TopToolbar,
//     ReferenceManyField,
//     Datagrid,
//     SelectInput,
//     ReferenceInput,
//     BooleanInput,
//     AutocompleteInput,
//     SelectArrayInput,
//     AutocompleteArrayInput,
//     ReferenceArrayInput,
//     ArrayInput,
//     SimpleFormIterator,
//     useRecordContext,
//     DateField,
//     FileInput,
//     FileField,
//     useDataProvider,
//     BooleanField,
//     Loading,
//     required,
//     ReferenceArrayField,
//     ChipField,
//     SingleFieldList,
//     TimeInput,
// } from 'react-admin';
import {useForm} from 'react-hook-form';

import {TextInput, TextArea, Select} from '@gravity-ui/uikit';
import {useState} from 'react';

// import {IconButton, Typography, Tooltip, Grid, Box, Stack} from '@mui/material';
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import AcUnitIcon from '@mui/icons-material/AcUnit';
// import {ReferenceManyInput} from '@react-admin/ra-relationships';
// import {AccordionForm, CreateInDialogButton, EditInDialogButton} from '@react-admin/ra-form-layout';
// import {SmartRichTextInput} from '@react-admin/ra-ai';
// import KitAppMinMaxDateOrTimeInput from '../components/ui/KitAppMinMaxDateOrTimeInput';

// let _ = require('lodash');

import filterByShowCondition from 'utils/filterByShowCondition';
// import optionRenderer from '../../../utils/optionRenderer';
// import DataGridChipTranslatable from '../../../utils/DataGridChipTranslatable';
// import makeObj from './makeObj';
// import optionRendererExtended from './optionRendererExtended';
// import PhotoField from '../../../utils/PhotoField';
// import ListPhotoField from './ListPhotoField';
// import EditToolbar from '../../../utils/EditToolbar';

const BuildFields = (props) => {
    // const [isChildLoading, setChildLoading] = useState(false);
    // const dataProvider = useDataProvider();
    // const record = useRecordContext();
    const form = useForm();
    const log = props.log === true;
    const subsDepth = props.subsDepth === undefined ? 0 : props.subsDepth;
    const autoCompleteOff = props.autoComplete === undefined ? 'off' : props.autoComplete;
    const useSourcePrefix = props.useSourcePrefix === true;
    const fields = filterByShowCondition(props.fields, props.source, form);

    return fields.map((v, i) => {
        const nsource = useSourcePrefix ? props.source + '.' + v.source : v.source;

        if (v.hidden !== true) {
            // let linked = v.props === undefined || v.props.filter === undefined ? {} : v.props.filter;

            // if (v.linked !== undefined) {
            //     try {
            //         const linkedValue = useWatch({
            //             name: v.linked.useSourcePrefix
            //                 ? props.source + '.' + v.linked.source
            //                 : v.linked.source,
            //         });
            //         if (linkedValue !== null && linkedValue !== undefined) {
            //             linked[v.linked.field] = v.linked.in === true ? [linkedValue] : linkedValue;
            //         }
            //     } catch (error) {
            //         console.log('-=-=-=-=-=-=- BuildFields linked', error, v.linked);
            //     }
            // }
            // if (v.linkedList !== undefined) {
            //     v.linkedList.forEach((linkedItem) => {
            //         try {
            //             const linkedValue = useWatch({
            //                 name: linkedItem.useSourcePrefix
            //                     ? props.source + '.' + linkedItem.source
            //                     : linkedItem.source,
            //             });
            //             if (linkedValue !== null && linkedValue !== undefined) {
            //                 linked[linkedItem.field] =
            //                     linkedItem.in === true ? [linkedValue] : linkedValue;
            //             }
            //         } catch (error) {
            //             console.log('-=-=-=-=-=-=- BuildFields linkedList item', error, linkedItem);
            //         }
            //     });
            // }
            if (v.type === 'text') {
                return (
                    <TextInput
                        placeholder={v.props.label}
                        autoComplete={autoCompleteOff}
                        size="l"
                        {...v.props}
                        label={v.props.leftLabel}
                        source={nsource}
                        key={i}
                    />
                );
            }
            // else if (v.type === 'simple_array_input') {
            //     let arr = [];
            //     let index_shift = v.indexShift === undefined ? 0 : v.indexShift;
            //     for (let index = 0; index < v.size; index++) {
            //         arr.push(index);
            //     }
            //     return arr.map((v2, i2) => {
            //         return (
            //             <TextInput
            //                 {...v.props}
            //                 label={v.labels === undefined ? v.label : v.labels[v2]}
            //                 source={nsource + '.' + (v2 + index_shift)}
            //                 autoComplete={autoCompleteOff}
            //                 key={i + '_' + v2}
            //             />
            //         );
            //     });
            // } else if (v.type === 'simple_array_number_input') {
            //     let arr = [];
            //     let index_shift = v.indexShift === undefined ? 0 : v.indexShift;
            //     for (let index = 0; index < v.size; index++) {
            //         arr.push(index);
            //     }
            //     return arr.map((v2, i2) => {
            //         return (
            //             <NumberInput
            //                 {...v.props}
            //                 label={v.labels === undefined ? v.label : v.labels[v2]}
            //                 source={nsource + '.' + (v2 + index_shift)}
            //                 autoComplete={autoCompleteOff}
            //                 key={i + '_' + v2}
            //                 onFocus={(e) =>
            //                     e.target.addEventListener(
            //                         'wheel',
            //                         function (e) {
            //                             e.preventDefault();
            //                         },
            //                         {passive: false},
            //                     )
            //                 }
            //             />
            //         );
            //     });
            // } else if (v.type === 'label') {
            //     if (_.has(v, 'props.reference')) {
            //         return (
            //             <ReferenceField {...v.props} source={nsource} key={i}>
            //                 <FunctionField render={(e) => optionRenderer(e, v)} />
            //             </ReferenceField>
            //         );
            //     } else {
            //         return <TextField {...v.props} source={nsource} key={i} />;
            //     }
            // } else if (v.type === 'json_field_file') {
            //     return (
            //         <>
            //             {isChildLoading ? <Loading /> : null}
            //             <FileInput
            //                 {...v.props}
            //                 onChange={(im) => {
            //                     setChildLoading(true);
            //                     uploadAndConnectFile(im, v, dataProvider)
            //                         .then((e) => {
            //                             setChildLoading(false);
            //                             form.setValue(nsource + '__upload_results', e);
            //                         })
            //                         .catch((error) => {
            //                             setChildLoading(false);
            //                             console.log('UPLOAD ERROR', error);
            //                         });
            //                 }}
            //                 source={nsource}
            //                 key={i}
            //             >
            //                 <FileField {...v.propsFileField} />
            //             </FileInput>
            //         </>
            //     );
            // } else if (v.type === 'number') {
            //     return (
            //         <NumberInput
            //             {...v.props}
            //             source={nsource}
            //             autoComplete={autoCompleteOff}
            //             key={i}
            //             onFocus={(e) =>
            //                 e.target.addEventListener(
            //                     'wheel',
            //                     function (e) {
            //                         e.preventDefault();
            //                     },
            //                     {passive: false},
            //                 )
            //             }
            //         />
            //     );
            // } else if (v.type === 'typography') {
            //     return (
            //         <Typography variant="h6" gutterBottom {...v.props}>
            //             {optionRenderer(undefined, v)}
            //         </Typography>
            //     );
            // } else if (v.type === 'bool') {
            //     return <BooleanInput {...v.props} source={nsource} key={i} />;
            // } else if (v.type === 'date') {
            //     return (
            //         <KitAppMinMaxDateOrTimeInput type="DateInput" v={v} nsource={nsource} key={i} />
            //     );
            // } else if (v.type === 'datetime') {
            //     return (
            //         <KitAppMinMaxDateOrTimeInput
            //             type="DateTimeInput"
            //             v={v}
            //             nsource={nsource}
            //             key={i}
            //         />
            //     );
            // } else if (v.type === 'time') {
            //     let time_val = _.get(record, nsource);
            //     let typeof_time = typeof time_val;
            //     if (typeof_time === 'string') {
            //         _.set(record, nsource, time_val.slice(0, 5));
            //     }
            //     return <TimeInput {...v.props} source={nsource} key={i} />;
            // }
            else if (v.type === 'select') {
                return (
                    <Select
                        width={v.props.fullWidth ? 'max' : null}
                        size="l"
                        {...v.props}
                        source={nsource}
                        key={i}
                        placeholder={v.props.label}
                        label={v.props.leftLabel}
                        options={v.props.choices.map((item, index) => {
                            return {value: item.id, content: item.name};
                        })}
                    />
                );
            }
            // else if (v.type === 'referecne_select') {
            //     return (
            //         <ReferenceInput {...v.props} filter={linked} source={nsource + '.id'} key={i}>
            //             <SelectInput {...v.propsSelectInput} />
            //         </ReferenceInput>
            //     );
            // } else if (v.type === 'referecne_autocomplete') {
            //     return (
            //         <ReferenceInput {...v.props} source={nsource} filter={linked} key={i}>
            //             <AutocompleteInput
            //                 {...v.propsAutocompleteInput}
            //                 optionText={(e) => optionRenderer(e, v)}
            //                 validate={v.propsAutocompleteInput.required ? required() : ''}
            //             />
            //         </ReferenceInput>
            //     );
            // } else if (v.type === 'referecne_select_many') {
            //     return (
            //         <ReferenceArrayInput {...v.props} filter={linked} source={nsource} key={i}>
            //             <SelectArrayInput
            //                 {...v.props2}
            //                 source={nsource}
            //                 filter={linked}
            //                 optionText={(e) => optionRenderer(e, v)}
            //                 key={i}
            //             />
            //         </ReferenceArrayInput>
            //     );
            // } else if (v.type === 'referecne_autocomplete_many') {
            //     return (
            //         <ReferenceArrayInput {...v.props} filter={linked} source={nsource} key={i}>
            //             <AutocompleteArrayInput
            //                 {...v.props2}
            //                 optionText={(e) => optionRenderer(e, v)}
            //             />
            //         </ReferenceArrayInput>
            //     );
            // }
            else if (v.type === 'rich_text_input') {
                return (
                    <TextArea
                        view="normal"
                        pin="round-brick"
                        rows={4}
                        {...v.props}
                        source={nsource}
                        key={i}
                        placeholder={v.props.label}
                    />
                );
            }
            // else if (v.type === 'simple_iterator') {
            //     return (
            //         <ArrayInput {...v.props} source={nsource} key={i}>
            //             <SimpleFormIterator {...v.simpleFormIteratorProps}>
            //                 <BuildFields
            //                     fields={v.fields}
            //                     subsDepth={subsDepth + 1}
            //                     log={false}
            //                     useSourcePrefix={true}
            //                 />
            //             </SimpleFormIterator>
            //         </ArrayInput>
            //     );
            // } else if (v.type === 'reference_many_iterator') {
            //     return (
            //         <ReferenceManyInput {...v.props} source={nsource} key={i}>
            //             <SimpleFormIterator {...v.simpleFormIteratorProps}>
            //                 <TextInput
            //                     style={{display: 'none'}}
            //                     source={v.reference_field}
            //                     defaultValue={record.id}
            //                 />
            //                 {v.default_values.map((v2, i2) => {
            //                     return (
            //                         <TextInput
            //                             style={{display: 'none'}}
            //                             source={v2.field}
            //                             defaultValue={v2.value}
            //                             key={v2 + '_' + i2}
            //                         />
            //                     );
            //                 })}
            //                 <BuildFields
            //                     fields={v.fields}
            //                     subsDepth={subsDepth + 1}
            //                     log={false}
            //                     useSourcePrefix={true}
            //                 />
            //             </SimpleFormIterator>
            //         </ReferenceManyInput>
            //     );
            // } else if (v.type === 'list_photo_field') {
            //     return <ListPhotoField {...props} {...v} source={nsource} key={i} />;
            // } else if (v.type === 'link') {
            //     return (
            //         <>
            //             <a
            //                 {...v.props}
            //                 href={'/order_report/' + record.uuid + v.url_suffix}
            //                 key={i + '_1'}
            //             >
            //                 {v.label}
            //             </a>{' '}
            //             |{' '}
            //             <a
            //                 {...v.props}
            //                 href={'/order_report/' + record.uuid + v.url_suffix + '?attachment=1'}
            //                 key={i + '_2'}
            //             >
            //                 Скачать
            //             </a>
            //         </>
            //     );
            // } else if (v.type === 'aria_label') {
            //     return (
            //         <Tooltip
            //             {...v.props}
            //             title={
            //                 <React.Fragment>
            //                     <div
            //                         dangerouslySetInnerHTML={{
            //                             __html: optionRendererExtended(record, v, props.source),
            //                         }}
            //                     />
            //                 </React.Fragment>
            //             }
            //             key={i}
            //         >
            //             <IconButton {...v.propsIconButton}>
            //                 <HelpOutlineIcon />
            //             </IconButton>
            //         </Tooltip>
            //     );
            // } else if (v.type === 'icon_required') {
            //     return <AcUnitIcon {...v.props} key={i} />;
            // } else if (v.type === 'box') {
            //     return (
            //         <Box {...v.props} key={i}>
            //             <BuildFields
            //                 fields={v.fields}
            //                 subsDepth={subsDepth + 1}
            //                 log={false}
            //                 useSourcePrefix={useSourcePrefix}
            //             />
            //         </Box>
            //     );
            // } else if (v.type === 'grid') {
            //     return (
            //         <Grid {...v.props} key={i}>
            //             <BuildFields
            //                 fields={v.fields}
            //                 subsDepth={subsDepth + 1}
            //                 log={false}
            //                 useSourcePrefix={useSourcePrefix}
            //             />
            //         </Grid>
            //     );
            // } else if (v.type === 'stack') {
            //     return (
            //         <Stack {...v.props} key={i}>
            //             <BuildFields
            //                 fields={v.fields}
            //                 subsDepth={subsDepth + 1}
            //                 log={false}
            //                 useSourcePrefix={useSourcePrefix}
            //             />
            //         </Stack>
            //     );
            // } else if (v.type === 'list_reference_many_field') {
            //     const customEditToolbar =
            //         v.customEditToolbar === undefined ? undefined : v.customEditToolbar;
            //     const CreateDialog = () => {
            //         return (
            //             <TopToolbar>
            //                 <CreateInDialogButton
            //                     fullWidth
            //                     maxWidth="md"
            //                     {...v.propsCreateInDialogButton}
            //                     record={makeObj(v.createDefaultValues, record)}
            //                 >
            //                     <SimpleForm>
            //                         <BuildFields
            //                             fields={v.createFields}
            //                             subsDepth={subsDepth + 1}
            //                             log={false}
            //                             useSourcePrefix={useSourcePrefix}
            //                         />
            //                     </SimpleForm>
            //                 </CreateInDialogButton>
            //             </TopToolbar>
            //         );
            //     };
            //     return (
            //         <List
            //             sx={{width: '100%'}}
            //             pagination={null}
            //             {...v.props}
            //             actions={<CreateDialog />}
            //             empty={<CreateDialog />}
            //             key={i}
            //         >
            //             <ReferenceManyField
            //                 {...v.propsReferenceManyField}
            //                 label={false}
            //                 filter={makeObj(v.listFilters, record)}
            //             >
            //                 <Datagrid>
            //                     {v.listFields.map((v, i) => {
            //                         if (v.type === 'date') {
            //                             return <DateField {...v.props} source={v.source} key={i} />;
            //                         } else if (v.type === 'boolean') {
            //                             return (
            //                                 <BooleanField {...v.props} source={v.source} key={i} />
            //                             );
            //                         } else if (v.type === 'reference_field') {
            //                             return (
            //                                 <ReferenceField {...v.props} source={v.source} key={i}>
            //                                     <FunctionField
            //                                         render={(e) => optionRenderer(e, v)}
            //                                     />
            //                                 </ReferenceField>
            //                             );
            //                         } else if (v.type === 'reference_array_field') {
            //                             return (
            //                                 <ReferenceArrayField
            //                                     {...v.props}
            //                                     source={v.source}
            //                                     key={i}
            //                                     link={false}
            //                                 >
            //                                     <SingleFieldList linkType={false}>
            //                                         <ChipField {...v.propsChip} />
            //                                     </SingleFieldList>
            //                                 </ReferenceArrayField>
            //                             );
            //                         } else if (v.type === 'text') {
            //                             return <TextField {...v.props} source={v.source} key={i} />;
            //                         } else if (v.type === 'number') {
            //                             return (
            //                                 <NumberField {...v.props} source={v.source} key={i} />
            //                             );
            //                         } else if (v.type === 'data_grid_chip_translatable') {
            //                             return (
            //                                 <DataGridChipTranslatable
            //                                     {...v.props}
            //                                     source={v.source}
            //                                     key={i}
            //                                 />
            //                             );
            //                         } else if (v.type === 'photo_field') {
            //                             return (
            //                                 <PhotoField {...v.props} source={v.source} key={i} />
            //                             );
            //                         } else {
            //                             return v.hidden === true ? null : (
            //                                 <TextField source={v.name} label={v.label} key={i} />
            //                             );
            //                         }
            //                     })}
            //                     <EditInDialogButton
            //                         fullWidth
            //                         maxWidth="lg"
            //                         {...v.propsEditInDialogButton}
            //                     >
            //                         <SimpleForm
            //                             toolbar={
            //                                 customEditToolbar === undefined ? undefined : (
            //                                     <EditToolbar {...customEditToolbar} />
            //                                 )
            //                             }
            //                         >
            //                             <BuildFields
            //                                 fields={v.editFields}
            //                                 subsDepth={subsDepth + 1}
            //                                 log={false}
            //                                 useSourcePrefix={useSourcePrefix}
            //                             />
            //                         </SimpleForm>
            //                     </EditInDialogButton>
            //                 </Datagrid>
            //             </ReferenceManyField>
            //         </List>
            //     );
            // } else if (v.type === 'accordionForm') {
            //     return (
            //         <AccordionForm.Panel {...v.props} key={i}>
            //             <BuildFields
            //                 fields={v.fields}
            //                 subsDepth={subsDepth + 1}
            //                 log={false}
            //                 useSourcePrefix={useSourcePrefix}
            //             />
            //         </AccordionForm.Panel>
            //     );
            // } else if (v.type === 'checkbox_group_input') {
            //     return <CheckboxGroupInput {...v.props} source={nsource} key={i} />;
            // } else if (v.type === 'nullable_boolean_input') {
            //     return <NullableBooleanInput {...v.props} source={nsource} key={i} />;
            // } else if (v.type === 'password_input') {
            //     return <PasswordInput {...v.props} source={nsource} key={i} />;
            // } else if (v.type === 'radio_button_group_input') {
            //     return <RadioButtonGroupInput {...v.props} source={nsource} key={i} />;
            // } else if (v.type === 'url_field') {
            //     return <UrlField {...v.props} source={nsource} key={i} />;
            // } else if (v.type === 'select_list_sub_reference') {
            //     return <SelectListSubRef {...v.props} source={nsource} linked={linked} key={i} />;
            // } else if (v.type === 'static_html') {
            //     return <div {...v.props} dangerouslySetInnerHTML={{__html: v.html}} key={i} />;
            // } else if (v.type === 'dynamic_html_from_record') {
            //     return (
            //         <div
            //             {...v.props}
            //             dangerouslySetInnerHTML={{__html: _.get(record, nsource)}}
            //             key={i}
            //         />
            //     );
            // }
            else {
                return null;
            }
        }
    });
};

export default BuildFields;
