import DefaultList from './DefaultList';
import DragAndDropList from './DragAndDropList';

const AdminEntityList = (props) => {
    const list = JSON.parse(props.entity.list);

    if (list.type == 'list') {
        return <DefaultList {...props} />;
    }
    if (list.type == 'dragAndDropList') {
        return <DragAndDropList {...props} />;
    }
};

export default AdminEntityList;
