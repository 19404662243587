import {Select} from '@gravity-ui/uikit';
import './styles.css';

const ReferenceAutocomplete = (props) => {
    return (
        <Select filterable={true} size="l" placeholder="L Size" width={'max'}>
            <Select.Option value="val_1">Value 1</Select.Option>
        </Select>
    );
};

export default ReferenceAutocomplete;
