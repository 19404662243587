import {Link} from '@gravity-ui/uikit';
import './styles.css';

export default function Error404({title = 'Страница не найдена'}) {
    return (
        <div>
            <h1>{title}</h1>
            <hr />
            <Link href="/admin/main">На главную</Link>
        </div>
    );
}
