import {configureStore} from '@reduxjs/toolkit';
import layoutsSlice, {LAYOUTS_PERSISTENT_STATE} from './layouts.slice';
import {saveState} from '.';

export const storage = configureStore({
    reducer: {
        adminConfigure: layoutsSlice,
    },
});

storage.subscribe(() => {
    saveState(storage.getState().adminConfigure.layouts, LAYOUTS_PERSISTENT_STATE);
});

export type RootState = ReturnType<typeof storage.getState>;
export type AppDispath = typeof storage.dispatch;
