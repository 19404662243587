import React, {useEffect, useState} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {useParams} from 'react-router-dom';
import {
    Button,
    UserLabel,
    Text,
    Row,
    Col,
    Dialog,
    TextInput,
    Select,
    Flex,
    Label,
    Modal,
    Palette,
} from '@gravity-ui/uikit';
import {GraduationCap} from '@gravity-ui/icons';
import BuildFields from 'components/AdminEntitys/BuildFields';
import GQLQuery from 'api/GQLQuery';
import './styles.css';

const DragAndDropList = (props) => {
    const reorder = (list, startIndex, endIndex) => {
        const result = list;
        const [removed] = result.items.splice(startIndex, 1);
        result.items.splice(endIndex, 0, removed);
        return result;
    };

    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = source;
        const destClone = destination;
        const [removed] = sourceClone.items.splice(droppableSource.index, 1);
        destClone.items.splice(droppableDestination.index, 0, removed);
        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;
        return result;
    };
    const grid = 6;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        padding: '10px 10px',
        margin: `0 0 ${grid}px 0`,
        borderRadius: '10px',

        // change background colour if dragging
        background: isDragging ? 'rgb(255, 197, 108)' : 'rgb(34, 36, 37)',
        border: '0.5px solid rgba(255, 140, 0, 0.5)',

        // styles we need to apply on draggables
        ...draggableStyle,
    });
    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? 'rgb(105, 105, 105)' : 'rgb(41, 44, 46)',
        padding: grid,
        borderRadius: '10px',
        margin: '10px',
        boxShadow: '0px 0px 20px -6px rgba(0, 0, 0, 0.35)',
    });

    const [dialogForm, setDialogForm] = useState({});
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);

    const params = useParams();

    const columnStatus = [
        {key: 'new', name: 'Новая'},
        {key: 'returned', name: 'Требуется уточнение'},
        {key: 'in_progress', name: 'В работе'},
        {key: 'testing', name: 'Тестирование'},
        {key: 'closed', name: 'Закрыта'},
    ];

    const [state, setState] = useState([]);

    const list = JSON.parse(props.entity.list);
    const create = JSON.parse(props.entity.create);
    const edit = JSON.parse(props.entity.edit);

    useEffect(() => {
        GQLQuery.listingForEntity(
            props.entity.id,
            params.layoutCode + '/' + params.entityCode,
            list.sort,
            {page: 1, perPage: 50},
            {},
        )
            .then((result) => {
                let initColumnStatus = [];
                columnStatus.forEach((e) => {
                    let itemsInColumns = [];
                    result.data.listingForEntity.data.forEach((listing) => {
                        if (listing.status == e.key) {
                            itemsInColumns.push(listing);
                        }
                    });
                    initColumnStatus.push({
                        title: e.name,
                        items: itemsInColumns,
                    });
                });
                setState(initColumnStatus);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [setState]);

    function onDragEnd(result) {
        const {source, destination} = result;
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;
        if (sInd === dInd) {
            const items = reorder(state[sInd], source.index, destination.index);
            const newState = [...state];
            newState[sInd] = items;
            setState(newState);
        } else {
            const result = move(state[sInd], state[dInd], source, destination);
            const newState = [...state];
            newState[sInd] = result[sInd];
            newState[dInd] = result[dInd];
            setState(newState);
        }
    }

    const createGroup = () => {
        if (dialogForm.name) {
            setState([
                ...state,
                {
                    title: dialogForm.name,
                    items: [],
                },
            ]);
            setOpen(false);
            setDialogForm({});
        }
    };

    const closeDialogForm = () => {
        setOpen(false);
        setOpen2(false);
        setOpen3(false);
        setDialogForm({});
    };

    return (
        <>
            <Button
                className="margin-10"
                view="outlined-action"
                size="l"
                onClick={() => setOpen(true)}
            >
                Добавить группу
            </Button>
            <Button
                className="margin-10"
                view="outlined-action"
                size="l"
                onClick={() => setOpen2(true)}
            >
                Добавить задачу
            </Button>
            <Row>
                <DragDropContext onDragEnd={onDragEnd}>
                    {state.map((element, index) => (
                        <Col className="col-20" key={index}>
                            <div className="text-center">{element.title}</div>

                            <Droppable droppableId={`${index}`}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                        {...provided.droppableProps}
                                    >
                                        {element.items.map((item, index) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={'' + item.id}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style,
                                                        )}
                                                    >
                                                        <div>
                                                            <Text
                                                                variant="subheader-2"
                                                                ellipsisLines={2}
                                                            >
                                                                {item.title}
                                                            </Text>

                                                            <Row className="mt-10">
                                                                {item.priority == 'lowest' ? (
                                                                    <>
                                                                        <Col s={6}>
                                                                            <Label theme="unknown">
                                                                                Неважный
                                                                            </Label>
                                                                        </Col>
                                                                        <Col
                                                                            s={6}
                                                                            className="text-right"
                                                                        >
                                                                            <Label theme="normal">
                                                                                {
                                                                                    item.created_at.split(
                                                                                        ' ',
                                                                                    )[0]
                                                                                }
                                                                            </Label>
                                                                        </Col>
                                                                    </>
                                                                ) : null}
                                                                {item.priority == 'low' ? (
                                                                    <>
                                                                        <Col s={6}>
                                                                            <Label theme="normal">
                                                                                Низкий
                                                                            </Label>
                                                                        </Col>
                                                                        <Col
                                                                            s={6}
                                                                            className="text-right"
                                                                        >
                                                                            <Label theme="normal">
                                                                                {
                                                                                    item.created_at.split(
                                                                                        ' ',
                                                                                    )[0]
                                                                                }
                                                                            </Label>
                                                                        </Col>
                                                                    </>
                                                                ) : null}
                                                                {item.priority == 'high' ? (
                                                                    <>
                                                                        <Col s={6}>
                                                                            <Label theme="warning">
                                                                                Высокий
                                                                            </Label>
                                                                        </Col>
                                                                        <Col
                                                                            s={6}
                                                                            className="text-right"
                                                                        >
                                                                            <Label theme="normal">
                                                                                {
                                                                                    item.created_at.split(
                                                                                        ' ',
                                                                                    )[0]
                                                                                }
                                                                            </Label>
                                                                        </Col>
                                                                    </>
                                                                ) : null}
                                                                {item.priority == 'critical' ? (
                                                                    <>
                                                                        <Col s={6}>
                                                                            <Label theme="danger">
                                                                                Критичный
                                                                            </Label>
                                                                        </Col>
                                                                        <Col
                                                                            s={6}
                                                                            className="text-right"
                                                                        >
                                                                            <Label theme="normal">
                                                                                {
                                                                                    item.created_at.split(
                                                                                        ' ',
                                                                                    )[0]
                                                                                }
                                                                            </Label>
                                                                        </Col>
                                                                    </>
                                                                ) : null}
                                                            </Row>

                                                            <Text
                                                                ellipsisLines={3}
                                                                className="text-content"
                                                            >
                                                                {/* {item.content} */}
                                                                Равным образом сложившаяся структура
                                                                организации требуют от нас анализа
                                                                существенных финансовых и
                                                                административных условий. Идейные
                                                                соображения высшего порядка, а также
                                                                рамки и место обучения кадров
                                                                позволяет оценить значение форм
                                                                развития.
                                                            </Text>
                                                            <Row>
                                                                <Col s={8}>
                                                                    <UserLabel
                                                                        type="person"
                                                                        avatar={{
                                                                            icon: GraduationCap,
                                                                        }}
                                                                    >
                                                                        Charles Darwin
                                                                    </UserLabel>
                                                                    {/* <User
                                                                        avatar={{
                                                                            imgUrl: 'https://loremflickr.com/640/480/cats?lock=8610182282084352',
                                                                        }}
                                                                        name="Isaac"
                                                                        description="user@gravity-ui.com"
                                                                        size="xs"
                                                                        style={{
                                                                            paddingRight: '20px',
                                                                        }}
                                                                    /> */}
                                                                </Col>
                                                                <Col s={4} className="text-right">
                                                                    <Button
                                                                        view="outlined-action"
                                                                        size="m"
                                                                        onClick={() => {
                                                                            setOpen3(true);
                                                                        }}
                                                                    >
                                                                        Открыть
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Col>
                    ))}
                </DragDropContext>
            </Row>

            {/* TODO: Карточка создания колонки(статус) */}
            <Dialog
                size="s"
                onClose={() => closeDialogForm()}
                open={open}
                onEnterKeyDown={() => createGroup()}
            >
                <Dialog.Header caption="Добавить группу" />
                <Dialog.Body>
                    <TextInput
                        placeholder="Название"
                        onChange={(input) => {
                            console.log(input.target.value);
                            setDialogForm(() => {
                                return {
                                    name: input.target.value,
                                };
                            });
                        }}
                        size="l"
                    />
                </Dialog.Body>
                <Dialog.Footer>
                    <Button view="outlined-action" size="l" onClick={() => closeDialogForm()}>
                        Отменить
                    </Button>
                    <Button
                        view="action"
                        disabled={!dialogForm.name}
                        size="l"
                        onClick={() => createGroup()}
                    >
                        Создать
                    </Button>
                </Dialog.Footer>
            </Dialog>

            {/* TODO: Карточка создания таска */}
            <Dialog
                size="m"
                onClose={() => closeDialogForm()}
                open={open2}
                onEnterKeyDown={() => {
                    alert('onEnterKeyDown');
                }}
            >
                <Dialog.Header caption="Добавить задачу" />
                <Dialog.Body>
                    <Flex direction="column" space="3">
                        {create.sections.map((section, index) => {
                            console.log('sectionCreate', section);
                            return <BuildFields {...section} key={index} />;
                        })}
                        {/* <TextInput label="Название" size="l" />
                        <TextInput label="Описание задачи" size="l" />
                        <Select size="l" placeholder="Важность" width={'max'}>
                            <Select.Option value="lowest">Неважный</Select.Option>
                            <Select.Option value="low">Низкий</Select.Option>
                            <Select.Option value="high">Высокий</Select.Option>
                            <Select.Option value="critical">Критичный</Select.Option>
                        </Select>
                        <Select size="l" placeholder="Статус" width={'max'}>
                            <Select.Option value="new">Новая</Select.Option>
                            <Select.Option value="returned">Требуется уточнение</Select.Option>
                            <Select.Option value="in_progress">В работе</Select.Option>
                            <Select.Option value="testing">Тестирование</Select.Option>
                            <Select.Option value="closed">Закрыта</Select.Option>
                        </Select> */}
                    </Flex>
                </Dialog.Body>
                <Dialog.Footer>
                    <Flex space="5" className="dialog-footer-button">
                        <Button view="outlined-action" size="l" onClick={() => closeDialogForm()}>
                            Отменить
                        </Button>
                        <Button
                            view="action"
                            disabled={!dialogForm.name}
                            size="l"
                            onClick={() => createGroup()}
                        >
                            Создать
                        </Button>
                    </Flex>
                </Dialog.Footer>
            </Dialog>

            {/* TODO: Карточка таска */}
            <Dialog
                size="m"
                onClose={() => closeDialogForm()}
                open={open3}
                onEnterKeyDown={() => {
                    alert('onEnterKeyDown');
                }}
            >
                <Dialog.Header caption="Добавить задачу" />
                <Dialog.Body>
                    <Flex direction="column" space="3">
                        {edit.sections.map((section, index) => {
                            console.log('sectionEdit', section);
                            return <BuildFields {...section} key={index} />;
                        })}
                        {/* <TextInput label="Название" size="l" />
                        <TextInput label="Описание задачи" size="l" />
                        <Select size="l" placeholder="Важность" width={'max'}>
                            <Select.Option value="lowest">Неважный</Select.Option>
                            <Select.Option value="low">Низкий</Select.Option>
                            <Select.Option value="high">Высокий</Select.Option>
                            <Select.Option value="critical">Критичный</Select.Option>
                        </Select>
                        <Select size="l" placeholder="Статус" width={'max'}>
                            <Select.Option value="new">Новая</Select.Option>
                            <Select.Option value="returned">Требуется уточнение</Select.Option>
                            <Select.Option value="in_progress">В работе</Select.Option>
                            <Select.Option value="testing">Тестирование</Select.Option>
                            <Select.Option value="closed">Закрыта</Select.Option>
                        </Select> */}
                    </Flex>
                </Dialog.Body>
                <Dialog.Footer>
                    <Flex space="5" className="dialog-footer-button">
                        <Button view="outlined-action" size="l" onClick={() => closeDialogForm()}>
                            Отменить
                        </Button>
                        <Button
                            view="action"
                            disabled={!dialogForm.name}
                            size="l"
                            onClick={() => createGroup()}
                        >
                            Сохранить
                        </Button>
                    </Flex>
                </Dialog.Footer>
            </Dialog>
        </>
    );
};

export default DragAndDropList;
