import {TextInput, useToaster, Tabs} from '@gravity-ui/uikit';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import ReferenceAutocomplete from 'components/ui/ReferenceAutocomlete';
import SimpleFormIterator from 'components/ui/SimpleFormIterator';

const AdminEntityEdit = () => {
    const toaster = useToaster();
    const params = useParams();

    // TODO:Эту цепочку хотелось бы в функцию для работы с лайаутами
    const adminLayout = useSelector((s) => s.adminConfigure.layouts);
    const layout = adminLayout.find((layout) => layout.code === params.layoutCode);
    const entity = layout.entities.find((entity) => entity.code === params.entityCode);
    // TODO:Эту цепочку хотелось бы в функцию для работы с лайаутами

    const [activeTab, setActiveTab] = useState(0);

    if (!entity) {
        return <Error404 title="Страница не найдена" />;
    }

    const edit = JSON.parse(entity.edit);
    let tabItems = [];
    if (tabItems) {
        edit.sections.map((field, index) => {
            tabItems.push({
                id: index,
                title: field.label,
                fields: field.fields,
            });
        });
    }

    return (
        <>
            <Tabs
                activeTab={activeTab}
                onSelectTab={(tabId) => setActiveTab(tabId)}
                items={tabItems}
            />
            {tabItems[activeTab] &&
                tabItems[activeTab].fields.map(
                    (field, index) => {
                        if (field.type == 'text') {
                            return (
                                <TextInput
                                    type={field.type}
                                    {...field.props}
                                    key={index}
                                    size="l"
                                />
                            );
                        } else if (field.type == 'number') {
                            return (
                                <TextInput
                                    type={field.type}
                                    size="l"
                                    key={index}
                                    {...field.props}
                                    onFocus={(e) =>
                                        e.target.addEventListener(
                                            'wheel',
                                            function (e) {
                                                e.preventDefault();
                                            },
                                            {passive: false},
                                        )
                                    }
                                />
                            );
                        } else if (field.type == 'referecne_autocomplete') {
                            return <ReferenceAutocomplete key={index} />;
                        } else if (field.type == 'simple_iterator') {
                            return <SimpleFormIterator {...field} key={index} />;
                        }
                    },
                    // <div>{field.type}</div>
                )}
        </>
    );
};

export default AdminEntityEdit;
