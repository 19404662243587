import * as icons from '@gravity-ui/icons';
import {Icon} from '@gravity-ui/uikit';

const Icons = (props) => {
    const size = props.size ? props.size : 16;
    if (props.name == 'Grip') {
        return <Icon data={icons.Grip} size={size} />;
    } else if (props.name == 'AbbrApi') {
        return <Icon data={icons.AbbrApi} size={size} />;
    } else {
        return <Icon data={icons.Grip} size={size} />;
    }
};

export default Icons;
